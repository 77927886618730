<template>
    <v-card>
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2"
        >
          mdi-email-outline
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="form.deviceId"
                  :label="$vuetify.lang.t('$vuetify.equipment.deviceId')"
                  clearable
                />
              </v-col>

              <v-col
                cols="4"
              >
                <v-autocomplete
                  v-model="form.emId"
                  :label="$vuetify.lang.t('$vuetify.equipmentTemplate.name')"
                  :items="depItems"
                  item-text="value"
                  item-value="key"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="form.company"
                  :label="$vuetify.lang.t('$vuetify.equipmentTemplate.company')"
                  clearable
                />
              </v-col>

              <v-col
                cols="12"
              >
                <v-spacer/>
                <v-btn
                  class="mx-2 mt-2 float-right"
                  color="primary"
                  fab
                  dark
                  small
                  @click="handleSearch"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>

  import { mapActions } from 'vuex';

  export default {
    data() {
      return {
        form: {
          deviceId: null,
          emId: null,
          company: null
        },
        depItems: []
      }
    },
    computed: {
    },

    created () {
      this.equipmentTemplateQueryAll().then((data) => {
        this.depItems = data
      })
    },

    methods: {

      ...mapActions({
        equipmentTemplateQueryAll: 'equipmentTemplate/queryAll'
      }),

      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
